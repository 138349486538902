import React, { useContext } from 'react';
import './menu-button.scss';
import { Store } from '../../Store';
import classNames from 'classnames';
import { toggleMainMenu } from '../../actions/appActions';

function MenuButton() {
  const {state: appState, dispatch: appDispatch} = useContext(Store);
  const { navOpen } = appState;

  function handleMenuButtonClick() {
    appDispatch(toggleMainMenu());
  }

  const menuButtonClasses = classNames('menu-button', {
    'is-active': navOpen
  });

  return (
    <div
      className={menuButtonClasses}
      onClick={handleMenuButtonClick}
    >
      <div className="menu-button__inner"></div>
      {/* <button className="hamburger" type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button> */}
    </div>
  );
}

export default MenuButton;
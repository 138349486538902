import React from 'react';
import './selection-button.scss';
import classNames from 'classnames';
import Button from '../Button';

function SelectionButton({ children, onClick, selected, action, value }) {

  const selectButtonClasses = classNames('selection__button', {
    selected: selected
  });

  return (
    <Button
      kind="ghost"
      className={selectButtonClasses}
      onClick={onClick}
      data-value={value}
      data-action={action}
    >
      {children}
    </Button>
  );
}

export default SelectionButton;
import React, { useEffect, useContext, useMemo } from 'react';
import './layout.scss';
import { Store } from '../../Store';
import { LocaleContext } from '../Locale';
import { useStaticQuery, graphql } from 'gatsby';
import { setCities, setCity, setHomepage, setSelection, setIsMobile, setMakeSelection } from '../../actions/appActions';
import Selection from '../Selection';
import MenuButton from '../MenuButton';
import Menu from '../Menu';
import Header from '../Header';
import PageTransition from '../PageTransition';

function Layout({ location, children, homepage = false}) {
  const { locale } = useContext(LocaleContext);
  const { dispatch: appDispatch } = useContext(Store);
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      craft {
        citiesEn: entries(section:[cities], site: "en") {
          ...on Craft_Cities {
            id
            title
            slug
            morningImage {
              id
              url
              title
            }
            afternoonImage {
              id
              url
              title
            }
            eveningImage {
              id
              url
              title
            }
          }
        }
        citiesFr: entries(section:[cities], site: "fr") {
          ...on Craft_Cities {
            id
            title
            slug
            morningImage {
              id
              url
              title
            }
            afternoonImage {
              id
              url
              title
            }
            eveningImage {
              id
              url
              title
            }
          }
        }
        citiesDe: entries(section:[cities], site: "de") {
          ...on Craft_Cities {
            id
            title
            slug
            morningImage {
              id
              url
              title
            }
            afternoonImage {
              id
              url
              title
            }
            eveningImage {
              id
              url
              title
            }
          }
        }
      }
    }
  `);

  const citiesDictionary = useMemo(() => {
    return {
      'en': { cities: data.craft.citiesEn },
      'fr': { cities: data.craft.citiesFr },
      'de': { cities: data.craft.citiesDe },
    }
  }, [data.craft.citiesEn, data.craft.citiesFr, data.craft.citiesDe]);

  // set cites state.
  useEffect(() => {
    const language = locale ? locale : 'en';
  
    const cities = citiesDictionary[language].cities.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});

    const citiesIds = citiesDictionary[language].cities.map(city => city.id);

    appDispatch(setCities(cities, citiesIds));

    // set active city from url
    if (typeof window !== undefined) {
      const pathName = window.location.pathname;
      const pathArray = pathName.split('/');

      // remove all path elements except for city pathname.
      for (let i = 0, len = pathArray.length; i < len; i ++) {
        if (pathArray[i] === '' || pathArray[i] === 'en' || pathArray[i] === 'fr' || pathArray[i] === 'de') {
          pathArray.splice(i, 1);
          i--;
        }
      }

      if (pathArray.length > 0) {
        const city = citiesDictionary[locale].cities.find(city => city.slug === pathArray[0]);

        if (city !== null) {
          appDispatch(setCity(city.id));
        }
      }

      // set selection if params are passed.
      if (location.search.length > 0) {
        const selectionParams = location.search.replace('?', '').split(',');
        const cityParam = selectionParams[0];
        const hoursParam = parseInt(selectionParams[1]);
        const todParam = selectionParams[2];
        let cityExists, hoursExists, todExists;

        // check if city exists
        const city = citiesDictionary[locale].cities.find(city => city.slug === cityParam);
        cityExists = typeof city !== 'undefined' ? true : false; 
        console.log('city', city);
        console.log('cityExists', cityExists);

        // check if hours is number
        hoursExists = typeof hoursParam === 'number' && [1,3,6,24].includes(hoursParam);

        // // check if todParam is of 'morning', 'afternoon', 'evening'
        todExists = true;
        // const todExists = ['morning', 'afternoon', 'evening'].includes(todParam);

        if (cityExists) {
          appDispatch(setSelection(city.id, 3, 'morning'));
        }
      }
    }

    // set isHomepage
    appDispatch(setHomepage(homepage));
  }, [locale, citiesDictionary, appDispatch, homepage, location.search]);

  // check if browser width is mobile or desktop
  useEffect(() => {
    function checkWidth() {
      if (matchMedia) {
        const bpMatch = window.matchMedia(`(min-width: 768px)`);

        if (bpMatch.matches) {
          appDispatch(setIsMobile(false));
        } else {
          appDispatch(setIsMobile(true));
        }
      }
    }

    if (window !== undefined) {
      checkWidth();
      window.addEventListener('resize', checkWidth);
    }

    return () => {
      window.removeEventListener('resize', checkWidth);
    }
  }, [appDispatch]);

  // useEffect(() => {
  //   console.log('location', location);

  //   if (location.state) {
  //     console.log('location state', location.state);
  //     console.log('location state', location.state.closeSelection);

  //     setTimeout(() => {
  //       appDispatch(setMakeSelection(false));
  //       window.scroll(0, 0);
  //     }, 60);

  //   }

  // }, [appDispatch, location]);

  return (
    <div className="layout">
      <Header /> 
      <MenuButton />
      <Menu />
      <Selection location={location} />
      <PageTransition location={location}>
        {children}
      </PageTransition>
    </div>
  );
}

export default Layout;
import React, { useContext } from 'react';
import './menu-bar.scss';
import navigatorLogo from './NavigatorLogo_vert.svg';
import viceRenaissanceLogo from './vice_x_renaissance_vert.svg';
import LocalizedLink from '../LocalizedLink';
import { Store } from '../../Store';
import classNames from 'classnames';
import { setMakeSelection } from '../../actions/appActions';

function MenuBar() {
  const { state: appState, dispatch: appDispatch } = useContext(Store);
  const { isHomepage, navOpen, makeSelection } = appState;

  function handleHomepageLinkClick() {
    appDispatch(setMakeSelection(false));
  }

  const menuBarClasses = classNames('menu-bar', {
    'menu-bar--hide-logo': navOpen,
    'menu-bar--hide': isHomepage && !navOpen && !makeSelection,
  });

  return (
    <div className={menuBarClasses}>
      <div className="menu-bar__background"></div>
      <div className="menu-bar__content">
        <LocalizedLink to="/" className="menu-bar__navigator-logo" onClick={handleHomepageLinkClick}>
          <img src={navigatorLogo} alt="Navigator" />
        </LocalizedLink>
        <img className="menu-bar__partnership-logo" src={viceRenaissanceLogo} alt="VICE x Renaissance Hotels" />
      </div>
    </div>
  );
}

export default MenuBar;
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './background-image-fade.scss';

function BackgroundImageFade({ src }) {
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={src}
        classNames="background-image-fade"
        timeout={{
          enter: 400,
          exit: 150
        }}
      >
        <div className="background-image background-image--overlay" style={{ backgroundImage: `url(${src})`}} />
      </CSSTransition>
    </TransitionGroup>
  );
}

export default BackgroundImageFade;
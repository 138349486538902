import React, { useContext } from 'react';
import './header.scss';
import classNames from 'classnames';
import { Store } from '../../Store';
import navigatorLogoWhite from '../../images/logos/NavigatorLogo.svg';
import navigatorLogoBlack from '../../images/logos/NavigatorLogoBlack.svg';
import SelectionSummary from '../SelectionSummary';
import LocalizedLink from '../LocalizedLink';

function Header() {
  const { state: appState } = useContext(Store);
  const { isHomepage, makeSelection, navOpen, timeOfDay } = appState;

  const headerClasses = classNames('header', {
    'header--homepage': isHomepage,
    'header--menu': navOpen,
    'header--selection': makeSelection,
  });

  return (
    <header className={headerClasses}>
      <div className="header__inner">
        <div className="header__logo">
          <LocalizedLink to="/">
            {makeSelection || navOpen || timeOfDay === 'evening'
              ? <img src={navigatorLogoWhite} alt="Navigator" />
              : <img src={navigatorLogoBlack} alt="Navigator" />
            }
          </LocalizedLink>
        </div>
        <div className="header__selection">
          <SelectionSummary />
        </div>
      </div>
    </header>
  );
}

export default Header;
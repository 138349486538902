import React, { useContext } from 'react';
import './menu.scss';
import MenuBar from '../MenuBar';
import {Store} from '../../Store';
import classNames from 'classnames';
import { LocaleContext } from '../Locale';
import { useStaticQuery, graphql } from 'gatsby';
import bonvoyLogo from '../../images/logos/Bonvoy.svg';
import viceLogo from '../../images/logos/Vice.svg';
import renaissanceLogo from '../../images/logos/Renaissance.svg';
import LanguageSelector from '../LanguageSelector';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import useTranslations from '../../hooks/useTranslations';

function Menu() {
  const { locale } = useContext(LocaleContext);
  const { state: appState } = useContext(Store);
  const { navOpen } = appState;
  const { renaissanceLink } = useTranslations();
  const data = useStaticQuery(graphql`
    query AboutQuery {
      craft {
        en: entry(section:[about], site: "en") {
          ...on Craft_About {
            id
            title
            body {
              content
            }
          }
        }
        fr: entry(section:[about], site: "fr") {
          ...on Craft_About {
            id
            title
            body {
              content
            }
          }
        }
        de: entry(section:[about], site: "de") {
          ...on Craft_About {
            id
            title
            body {
              content
            }
          }
        }
      }
    }
  `);

  const menuClasses = classNames('menu', {
    'menu--open': navOpen
  });

  return (
    <div className={menuClasses}>
      <MenuBar />
      <div className="menu__content">
        <LanguageSelector />
        <div className="menu__text" dangerouslySetInnerHTML={{__html: data.craft[locale].body.content}} />
        <div className="menu__logos">
          <div className="menu__logo">
            <OutboundLink
              href={`https://www.vice.com`}
              target="_blank"
              rel="noopener noreferrer">
              <img src={viceLogo} alt="VICE" />
            </OutboundLink>
          </div>

          <div className="menu__logo">
            <OutboundLink
              href={renaissanceLink}
              target="_blank"
              rel="noopener noreferrer">
              <img src={renaissanceLogo} alt="Renaissance Hotels" />
            </OutboundLink>
          </div>

          <div className="menu__logo">
            <img src={bonvoyLogo} alt="Marriot Bonvoy" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
// eslint-disable-next-line
import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from '../components/Locale';

function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = useContext(LocaleContext);

  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0];
  
  return translations;
}

export default useTranslations;

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            welcome
            morning
            afternoon
            evening
            startYourJourney
            imIn
            forHours
            inThe
            goExplore
            myJourney
            showMore
            showLess
            recommendedBy
            locateError
            visitWebsite
            share
            tweet
            unableToRetrieveLocation
            locationPermissionsDenied
            webGLNotSupported
            renaissanceLink
          }
        }
      }
    }
  }
`;
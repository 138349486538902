import React, { useContext, useEffect, useState } from 'react';
import { LocaleContext } from '../Locale';
import locales from '../../../config/locales';
import './language-selector.scss';

const ls = Object.keys(locales).reduce((acc, value) => {
  // remove default from array.
  if (value !== 'default') {
    acc.push(locales[value]);
  }
  return acc;
}, []);

function LanguageSelector() {
  const { locale } = useContext(LocaleContext);
  const [languages, setLanguages] = useState(ls);

  useEffect(() => {
    const sortedLanguages = ls.sort((x, y) => x.locale === locale ? -1 : y.locale === locale ? 1 : 0);

    setLanguages(sortedLanguages);
  }, [locale]);

  return (
    <ul className="language-selector">
      {languages.map((language, index) => (
        <li key={index} className="language">
          <a href={`/${language.path}`}>{language.siteLanguage}</a>
        </li>
      ))}
    </ul>
  );
}

export default LanguageSelector;
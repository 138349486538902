import React, { useContext, useReducer, useEffect, useCallback } from 'react';
import './selection.scss';
import { Store } from '../../Store';
import { LocaleContext } from '../Locale';
import useTranslations from '../../hooks/useTranslations';
import { setSelection, setMakeSelection } from '../../actions/appActions';
import { navigate } from 'gatsby';
import { localizedPathHelper } from '../../utils';
import classNames from 'classnames';
import BackgroundImageFade from '../BackgroundImageFade';
import SelectionButton from '../SelectionButton';
import CtaButton from '../CtaButton';
import useWhyDidYouUpdate from '../../hooks/useWhyDidYouUpdate';
import usePrevious from '../../hooks/usePrevious';

const hoursOptions = [
  {value: 1, label: '1'},
  {value: 3, label: '3'},
  {value: 6, label: '6'},
  {value: 24, label: '24'},
];

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CITY':
      return {
        ...state,
        city: action.payload
      }
    case 'SET_HOURS':
      return {
        ...state,
        hours: action.payload
      }
    case 'SET_TIME_OF_DAY':
      return {
        ...state,
        timeOfDay: action.payload
      }
    default:
      return state;
  }
}

function SelectionBackgroundImage({
    morningImage,
    afternoonImage,
    eveningImage,
    timeOfDay
  }) {

  const defaultImage = 'http://placehold.it/1280x720';
  const imageSources = {
    'morning': morningImage.length ? morningImage[0].url : defaultImage,
    'afternoon': afternoonImage.length ? afternoonImage[0].url : defaultImage,
    'evening': eveningImage.length ? eveningImage[0].url : defaultImage,
  };

  return (
    <BackgroundImageFade src={imageSources[timeOfDay]} />
  );
}

const MemoizedSelectionBackgroundImage = React.memo(SelectionBackgroundImage);

function Selection({ location }) {
  const { locale } = useContext(LocaleContext);
  const { state: appState, dispatch: appDispatch } = useContext(Store);
  const { morning, afternoon, evening, goExplore, imIn, forHours, inThe } = useTranslations();

  const initialState = {
    city: appState.city,
    hours: appState.hours,
    timeOfDay: appState.timeOfDay,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const prevCitiesIds = usePrevious(appState.citiesIds);
  const prevLocation = usePrevious(location);

  // useWhyDidYouUpdate('Selection location', location);
  // useWhyDidYouUpdate('Selection appstate', appState);
  // useWhyDidYouUpdate('Selection localState', state);

  const timeOfDayOptions = [
    {value: 'morning', label: `${morning}`},
    {value: 'afternoon', label: `${afternoon}`},
    {value: 'evening', label: `${evening}`}
  ];

  function handleSelectionButtonClick(e) {
    const el = e.currentTarget;
    const action = el.dataset.action;
    const value = isNaN(el.dataset.value) ? el.dataset.value : parseInt(el.dataset.value);
    
    dispatch({ type: `SET_${action}`, payload: value });

    // set default to morning if '24h' is selected
    if (action === 'HOURS' && value === 24) {
      dispatch({ type: `SET_TIME_OF_DAY`, payload: 'morning' });
    }
  }

  function handleSubmitSelection() {
    appDispatch(setSelection(
      state.city,
      state.hours,
      state.hours === 24 ? 'morning' : state.timeOfDay
    ));

    if (state.city !== '') {
      const selectedCity = appState.cities[state.city];
      const navigateToPath = localizedPathHelper(`/${selectedCity.slug}`, locale);
      console.log('navigateToPath', navigateToPath);
      navigate(`/${navigateToPath}`, {
        state: { closeSelection: true }
      });
    }

    setTimeout(() => {
      appDispatch(setMakeSelection(false));
      window.scroll(0, 0);
    }, 60);

    trackSelection(appState.cities[state.city].title, state.hours, state.hours === 24 ? 'morning' : state.timeOfDay);
  }

  function trackSelection(city, hours, timeOfDay) {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'selection', {
        'event_category': 'navigator_selection',
        'event_label': `${city}, ${hours}, ${timeOfDay}`,
      });

      window.gtag('event', 'city', {
        'event_category': 'navigator_selection',
        'event_label': city,
      });

      window.gtag('event', 'hours', {
        'event_category': 'navigator_selection',
        'event_label': hours,
      });

      window.gtag('event', 'timeOfDay', {
        'event_category': 'navigator_selection',
        'event_label': timeOfDay,
      });
    }
  }

  useEffect(() => {
    if (appState.city === null && appState.citiesIds.length > 0) {
      // set selected city to first city
      dispatch({ type: 'SET_CITY', payload: appState.cities[appState.citiesIds[0]].id });
    } else {
      dispatch({ type: 'SET_CITY', payload: appState.city });
    }
    
    dispatch({ type: 'SET_HOURS', payload: appState.hours });
  }, [appState.city, appState.hours, appState.cities, appState.citiesIds]);

  useEffect(() => {
    // setTimeout(() => {
    //   appDispatch(setMakeSelection(false));
    //   window.scroll(0, 0);
    // }, 60);
  }, [appState.city, appState.hours, appState.timeOfDay, appDispatch, location]);

  const selectionClasses = classNames('selection', {
    'is-active': appState.makeSelection
  });

  return (
    <>
      {Object.keys(appState.cities).length > 0 && 
        <div className={selectionClasses}>
          {state.city !== null && 
            <MemoizedSelectionBackgroundImage
              morningImage={appState.cities[state.city].morningImage}
              afternoonImage={appState.cities[state.city].afternoonImage}
              eveningImage={appState.cities[state.city].eveningImage}
              timeOfDay={state.timeOfDay}
            />
          }
          <div className="selection__content">
            <div className="selection__section">
              <h2 className="selection__section-title">{imIn}:</h2>
              {appState.citiesIds.map((city, index) => (
                <SelectionButton
                  key={appState.cities[city].id}
                  selected={state.city === appState.cities[city].id}
                  onClick={handleSelectionButtonClick}
                  value={appState.cities[city].id}
                  action="CITY"
                >
                  {appState.cities[city].title}
                </SelectionButton>
              ))}
            </div>
            <div className="selection__section">
              <h2 className="selection__section-title">{forHours}:</h2>
              {hoursOptions.map((hours, index) => (
                <SelectionButton
                  key={`${hours.value}h`}
                  selected={state.hours === hours.value}
                  onClick={handleSelectionButtonClick}
                  value={hours.value}
                  action="HOURS"
                >
                  {`${hours.label}h`}
                </SelectionButton>
              ))}
            </div>

            {state.hours !== 24 && 
              <div className="selection__section">
                <h2 className="selection__section-title">{inThe}:</h2>
                {timeOfDayOptions.map((tod, index) => (
                  <SelectionButton
                    key={tod.value}
                    selected={state.timeOfDay === tod.value}
                    onClick={handleSelectionButtonClick}
                    value={tod.value}
                    action="TIME_OF_DAY"
                  >
                    {tod.label}
                  </SelectionButton>
                ))}
              </div>
            }
            
            {state.city !== null && 
              <CtaButton
                className="mt-4"
                text={goExplore}
                onClick={handleSubmitSelection}
                arrowDirection="down"
              />
            }
          </div>
        </div>
      }
    </>
  );
}

export default Selection;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './background-image.scss';
import classNames from 'classnames';

function BackgroundImage({ src, className = '' }) {
  const [inProp, setInProp] = useState(false);

  const backgroundImageStyle = {
    backgroundImage: `url(${src})`,
  }

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setInProp(true);
    }

    return () => {
      setInProp(false);
    }
  }, [src]);

  const backgroundImageClasses = classNames(`background-image ${className}`);

  return (
    <CSSTransition
      in={inProp}
      timeout={120}
      classNames="background-image"
      unmountOnExit
    >
      <div className={backgroundImageClasses} style={backgroundImageStyle} />
    </CSSTransition>
  );
}

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired
};

export default BackgroundImage;
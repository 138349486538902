import React, { useContext } from 'react';
import './selection-summary.scss';
import { Store } from '../../Store';
import { setMakeSelection } from '../../actions/appActions';
import SelectionButton from '../SelectionButton';
import useTranslations from '../../hooks/useTranslations';

function SelectionSummary() {
  const { morning, afternoon, evening } = useTranslations();
  const { state: appState, dispatch: appDispatch } = useContext(Store);
  const { city, cities, hours, timeOfDay } = appState;

  const timeOfDayDict = {
    'morning': morning,
    'afternoon': afternoon,
    'evening': evening
  };

  function handleSummaryClick() {
    appDispatch(setMakeSelection(true));
  }

  return (
    <div className="selection-summary" onClick={handleSummaryClick}>
      {city &&
        <SelectionButton selected={true}>{cities[city].title}</SelectionButton>
      }

      {hours &&
        <SelectionButton selected={true}>{hours}h</SelectionButton>
      }

      {hours !== 24 && timeOfDay &&
        <SelectionButton selected={true}>{timeOfDayDict[timeOfDay]}</SelectionButton>
      }
    </div>
  );
}

export default SelectionSummary;
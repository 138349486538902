import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.scss';

function Button({
  children,
  className,
  disabled,
  size,
  href,
  type,
  kind,
  ...other
}) {
  const buttonClasses = classNames(className, {
    btn: true,
    'px-4 py-1': true,
    [`btn--sm`]: size === `small`,
    [`btn--lg`]: size === `large`,
    [`btn--primary`]: kind === `primary`,
    [`btn--secondary`]: kind === `secondary`,
    [`btn--ghost`]: kind === `ghost`,
    [`btn--disabled`]: kind === `disabled`,
  });

  const commonProps = {
    className: buttonClasses
  }

  let component = 'button';

  let otherProps = {
    disabled,
    type
  };

  const anchorProps = {
    role: 'button',
    href
  };

  if (href) {
    component = 'a';
    otherProps = anchorProps;
  }

  return React.createElement(
    component,
    {
      ...other,
      ...commonProps,
      ...otherProps,
    },
    children
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  kind: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
  size: PropTypes.oneOf(['default', 'small', 'large']),
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  kind: 'primary',
  size: 'default',
};

export default Button;